import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#006494',
    },
    secondary: {
      main: '#E4F2FF',
    },
  },
});

export default theme;
