import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';

import './index.scss';
import { capitalizeString } from '../../helpers/capitalizeString';
import { useAlert } from '../../context';
import { AlertError } from '../Alerts';

const DeleteConfirmModal = ({ showModal, setStates, deleteCard, title }) => {
  const { textErrorAlert } = useAlert();

  return (
    <Modal
      fullscreen="sm-down"
      show={showModal}
      onHide={() => setStates(prevState => ({ ...prevState, showModal: false }))}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Deletar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="p">
            Tem certeza que deseja excluir os conteúdos de:{' '}
            <strong>{capitalizeString(title)}</strong> ?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              type="button"
              variant="contained"
              className="btn-sec"
              onClick={() => setStates(prevState => ({ ...prevState, showModal: false }))}
              sx={{
                alignSelf: 'end',
                fontWeight: 600,
                width: '150px',
                height: '44px',
                border: '1px solid #006494',
                borderRadius: '10px',
                color: 'primary.main',
                backgroundColor: 'white',
                margin: '20px 16px 0',
              }}
            >
              Não
            </Button>
            <Button
              type="button"
              variant="contained"
              className="btns"
              onClick={deleteCard}
              sx={{
                alignSelf: 'end',
                fontWeight: 600,
                width: '150px',
                height: '44px',
                borderRadius: '10px',
                backgroundColor: 'primary.main',
                margin: '20px 16px 0',
              }}
            >
              Sim
            </Button>
          </Box>
        </Box>
      </Modal.Body>
      <AlertError textAlert={textErrorAlert} />
    </Modal>
  );
};

export default DeleteConfirmModal;
