import React, { useState } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { capitalizeString } from '../../helpers/capitalizeString';

import './index.scss';

const SelectCategory = ({ optionsItems, setValue, value }) => {
  const [openDropDown, setOpenDropDown] = useState(false);

  return (
    <div className="dropdown-container-select">
      <div className="dropdown-select" onClick={() => setOpenDropDown(!openDropDown)}>
        <span className="select">{capitalizeString(value)}</span>
        <ArrowDropDownOutlinedIcon />
      </div>
      <div
        className={`dropdown-list ${openDropDown ? 'open-dropdown' : 'close-dropdown'}`}
      >
        {optionsItems.map((item, index) => (
          <div
            className="item"
            key={`${item.slug}-${item.value}-${index}`}
            onClick={() => {
              setOpenDropDown(!openDropDown);
              setValue(item.value);
            }}
          >
            {capitalizeString(item.slug)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCategory;
