import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../containers/footer';
import Header from '../containers/Header';
import BookCard from '../components/book_card/book_card.js';
import Loading from '../components/loading/loading.js';
import { getContentsByCategory } from '../services/contents.js';
import { getCategory } from '../services/categories.js';
import ContainerTitleSearch from '../components/ContainerTitleSearch';
import { Box, Button, Typography } from '@mui/material';
import { capitalizeString } from '../helpers/capitalizeString';

import '../components/book_card/book.scss';

const ContentList = ({ match }) => {
  const { categoryId } = useParams();
  const initialState = {
    page: 1,
    loading: true,
    contents: [],
    categorySlug: '',
    end: true,
  };

  const [states, setStates] = useState(initialState);

  useEffect(() => {
    getContentsByCategory(categoryId, 1).then(response =>
      setStates(prevState => ({ ...prevState, contents: response.data, loading: false }))
    );

    getCategory(categoryId).then(response =>
      setStates(prevState => ({ ...prevState, categorySlug: response.data.slug }))
    );
  }, [categoryId]);

  useEffect(() => {
    if (states.contents.length === 12) {
      getContentsByCategory(categoryId, states.page + 1).then(response => {
        if (response.data.length > 0) {
          setStates(prevState => ({
            ...prevState,
            end: false,
          }));
        }
      });
    }
  }, [categoryId, states.page, states.contents]);

  const nextPage = () => {
    setStates(prevState => ({ ...prevState, page: states.page + 1 }));
    getContentsByCategory(categoryId, states.page + 1).then(response => {
      if (response.data.length > 0) {
        setStates(prevState => ({
          ...prevState,
          contents: prevState.contents.concat(response.data),
        }));
        if (response.data.length < 12) {
          setStates(prevState => ({ ...prevState, end: true }));
        }
      } else {
        setStates(prevState => ({ ...prevState, end: true }));
      }
    });
  };

  const Cards = () => {
    if (states.contents.length > 0) {
      return (
        <>
          <Box className={'content-container'}>
            {states.contents.map((content, index) => (
              <BookCard
                key={`${content.id}-${content.title}-${index}-A`}
                categoryId={categoryId}
                premium={content.premium}
                contentId={content.id}
                categorySlug={states.categorySlug}
                title={content.title}
                description={content.description}
                link={content.locale}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {!states.end && (
              <Button
                type="button"
                variant="contained"
                onClick={nextPage}
                className="btns"
                sx={{
                  fontWeight: 600,
                  width: '250px',
                  height: '44px',
                  borderRadius: '10px',
                  backgroundColor: 'primary.main',
                  marginTop: '15px',
                }}
              >
                Carregar mais arquivos
              </Button>
            )}
          </Box>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '50px',
          '@media (max-width: 600px)': {
            marginTop: 0,
            marginBottom: '40px',
          },
        }}
      >
        <Typography variant="h5">
          Não há arquivos para{' '}
          <strong>{`${capitalizeString(states.categorySlug)}`}</strong>.
        </Typography>
        <button
          className="card-button btns"
          style={{ width: '204px', marginTop: '24px', padding: '0' }}
          onClick={() => (window.location.href = '/waterdb')}
        >
          Voltar para categorias
        </button>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header loggedIn={true} />
      <ContainerTitleSearch
        pageTitle={states.categorySlug}
        placeholder="Buscar arquivos"
      />
      <Box
        sx={{
          borderRadius: '15px',
          padding: '0 50px',
          flexGrow: 1,
          '@media (max-width: 1024px)': {
            padding: '0 20px',
          },
        }}
      >
        {states.loading ? <Loading /> : <Cards />}
      </Box>
      <Footer />
    </Box>
  );
};

export default ContentList;
