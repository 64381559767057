import React, { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import './input.scss';
import { IconButton } from '@mui/material';

const InputPassword = ({ label, name, value, onChange, required, ...other }) => {
  const [showPassword, setShowPassword] = useState(false);

  const changeVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="material-textfield">
      <input
        value={value}
        onChange={onChange}
        placeholder=" "
        name={name}
        type={showPassword ? "text" : "password"}
        required
        {...other}
      />
      {required ? <label>{`${label}*`}</label> : <label>{label}</label>}
      <IconButton
        onClick={changeVisibility}
        sx={{
          position: 'absolute',
          right: 0,
          top: ' 5px',
        }}
      >
        {showPassword ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </IconButton>
    </div>
  );
};

export default InputPassword;
