import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import './input.scss';

const FileButton = ({onChange, style }) => {

  const fileInput = React.useRef();

  return (
    <div className='file-button-container' style={style}>
      <button 
        onClick={e => fileInput.current && fileInput.current.click()}
        className="file-button"
      >
        <AddOutlinedIcon />
        <span>Adicionar arquivo</span>
      </button>
      <input
        ref={fileInput}
        type="file"
        style={{ display: 'none' }} 
        onChange={onChange}
        id="inputFile"
      />
    </div>
  );
}

export default FileButton;
