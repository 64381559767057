import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { isLoggedIn } from './helpers/helpers_general.js';

import Login from './views/Login.js';
import Register from './views/Register.js';
import SendTokenPassword from './views/SendTokenPassword.js';
import RecoverPassword from './views/RecoverPassword.js';
import RecoverPasswordWithoutToken from './views/RecoverPasswordWithoutToken.js';
import Home from './views/Home.js';
import ContentList from './views/ContentList.js';
import Profile from './views/profile.js';
import UserList from './views/UserList.js';
import SearchView from './views/SearchView.js';

const Main = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/register" component={Register}></Route>
      <Route exact path="/send_token_password" component={SendTokenPassword}></Route>
      <Route exact path="/recover_password" component={RecoverPassword}></Route>
      <Route
        exact
        path="/recover_password_without_token"
        component={RecoverPasswordWithoutToken}
      ></Route>
      <Route exact path="/waterdb" component={isLoggedIn() ? Home : Login}></Route>
      <Route
        exact
        path="/waterdb/search/:search"
        component={isLoggedIn() ? SearchView : Login}
      ></Route>
      <Route
        exact
        path="/waterdb/category/:categoryId"
        component={isLoggedIn() ? ContentList : Login}
      ></Route>
      <Route exact path="/waterdb/profile/:id" component={Profile}></Route>
      <Route exact path="/waterdb/user" component={isLoggedIn() ? UserList : Login}></Route>
      <Route exact path="/waterdb/logs" component={Home}></Route>
    </Switch>
  );
};

export default Main;
