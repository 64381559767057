import React, { Component } from 'react';
import LoadImage from '../../assets/images/icons/load.gif'

import './loading.scss'

class Loading extends Component{
  render(){
    return (
      <div className="load">
      <img alt="imagem de carregamento" src={LoadImage}/>
      </div>
    )
  }
}

export default Loading;
