import React, { useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import BookInformation from './book_information.js';
import PremiumIcon from '../../assets/images/icons/PremiumIcon.svg';
import TrashIcon from '../../assets/images/icons/TrashIcon.svg';
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import { isAdmin } from '../../helpers/helpers_general.js';
import { deleteContent } from '../../services/contents.js';

import './book.scss';
import NewContentModal from '../modals/NewContentModal.js';
import DeleteConfirmModal from '../modals/DeleteConfirmModal.js';
import { useAlert } from '../../context/index.js';
import { AlertSuccess } from '../Alerts/index.js';

const BookCard = props => {
  const { contentId, categoryId, title, description, premium } = props;

  const initialState = {
    showModal: false,
    showNewContentModal: false,
    showContentModal: false,
  };

  const [states, setStates] = useState(initialState);
  const {
    setShowError,
    setShowSuccess,
    setTextSuccessAlert,
    setTextErrorAlert,
    textSuccessAlert,
  } = useAlert();

  const deleteCard = () => {
    deleteContent(contentId).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Arquivo deletado com sucesso!');
        setStates(prevState => ({ ...prevState, showModal: false }));
        setTimeout(() => window.location.reload(), 1500);
      },
      error => {
        setShowError(true);
        setTextErrorAlert('Um erro ocorreu ao deletar o arquivo.');
        setStates(prevState => ({ ...prevState, showModal: false }));
      }
    );
  };

  const isPremiumContent = premium && (
    <img style={{ marginRight: '5px' }} alt="conteúdo premium" src={PremiumIcon} />
  );

  const isAdminHeader = isAdmin() && (
    <Box sx={{ marginLeft: '5px' }}>
      {isPremiumContent}
      <IconButton
        aria-label="edit"
        onClick={() =>
          setStates(prevState => ({ ...prevState, showNewContentModal: true }))
        }
      >
        <img src={EditIcon} alt="Editar Categoria" />
      </IconButton>
      <IconButton
        aria-label="trash"
        onClick={() => setStates(prevState => ({ ...prevState, showModal: true }))}
      >
        <img src={TrashIcon} alt="Deletar Categoria" />
      </IconButton>
    </Box>
  );

  const Modals = () => (
    <>
      <DeleteConfirmModal
        showModal={states.showModal}
        setStates={setStates}
        deleteCard={deleteCard}
        title={title}
      />

      <NewContentModal
        title="Editar Conteúdo"
        card={props}
        categoryId={categoryId}
        showNewContentModal={states.showNewContentModal}
        setStates={setStates}
      />

      <BookInformation
        showContentModal={states.showContentModal}
        setModalStates={setStates}
        contentId={contentId}
        title={title}
        description={description}
      />

      <AlertSuccess textAlert={textSuccessAlert} />
    </>
  );

  return (
    <Box className="content-wrapper">
      <Card className="content-card">
        <CardHeader
          sx={{ margin: '10px 10px 0', padding: '0' }}
          title={
            <Typography
              variant="h3"
              className="card-title"
              sx={{
                height: '48px',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#1C1B1F',
                '@media (max-width: 600px)': {
                  fontSize: ' 14px',
                },
              }}
            >
              {title}
            </Typography>
          }
          action={isAdminHeader || isPremiumContent}
        />
        <CardContent className="card-content" sx={{ padding: '10px 5px 0 10px' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: ' 16px',
              lineHeight: '20px',
              textAlign: 'left',
              color: '#49454F',
              '@media (max-width: 600px)': {
                fontSize: ' 14px',
              },
            }}
            className="content-description"
            variant="h6"
            >
            {description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'right',
            flexGrow: 1,
            padding: 0,
            width: '100%',
          }}
          >
          <button
            className="card-button"
            onClick={() =>
              setStates(prevState => ({ ...prevState, showContentModal: true }))
            }
          >
            Ver mais
          </button>
        </CardActions>
      </Card>
      <Modals />
    </Box>
  );
};

export default BookCard;
