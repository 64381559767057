import React, { useEffect, useState } from 'react';

import { uploadFileS3 } from '../../../services/file.js';
import { getCategories } from '../../../services/categories.js';
import { getCategory } from '../../../services/categories.js';
import { saveContent, editContent } from '../../../services/contents.js';
import Loading from '../../../components/loading/loading.js';
import { getLocale } from '../../../helpers/helpers_general.js';
import { FormControlLabel, Switch, Box, Button, Stack, Alert } from '@mui/material';
import Input from '../../../components/Input';
import Select from '../../../components/Select/Select.js';
import SelectCategory from '../../../components/Select/SelectCategory.js';
import TextField from '../../../components/TextField/index.js';
import FileInput from '../../../components/Input/fileInput.js';
import { useAlert } from '../../../context/index.js';
import FileButton from '../../../components/Input/fileButton.js';


const ContentForm = ({ card, categoryId, setShowContentModal }) => {
  const initialState = {
    title: '',
    description: '',
    locale: '',
    fileName: '',
    premium: false,
    categories: [],
    documents: [],
    loading: false,
    loadingFile: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: '',
  };

  const [selectCategoryLabel, setSelectCategoryLabel] = useState('Categoria');
  const [categoryOption, setCategoryOption] = useState(null);
  const [states, setStates] = useState(initialState);
  const { setShowError, setShowSuccess, setTextSuccessAlert, setTextErrorAlert } =
    useAlert();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setStates(prevState => ({ ...prevState, mobileView: true }))
        : setStates(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    getCategories().then(response =>
      setStates(prevState => ({ ...prevState, categories: response.data }))
    );

    if (card !== {} && card !== undefined) {
      getCategory(categoryId).then(response =>
        setSelectCategoryLabel(response.data.slug)
      );
      setCategoryOption(categoryId);
      setStates(prevState => ({
        ...prevState,
        id: card.contentId,
        title: card.title,
        description: card.description,
        locale: getLocale(),
        premium: card.premium,
        error: false,
        success: false,
      }));
    }
  }, [card, categoryId]);

  const uploadFile = e => {
    e.preventDefault();
    setStates(prevState => ({ ...prevState, loadingFile: true }));
    uploadFileS3(e, states.title).then(data => {
      if (data !== undefined) {
        setStates(prevState => ({
          ...prevState,
          loadingFile: false,
          fileName: data.key,
          error: false,
          success: true,
          successMessage: 'Arquivo salvo com sucesso.',
          documents: states.documents.concat({
            file_locale: data.location,
            file_name: data.key,
          }),
        }));
      } else {
        setStates(prevState => ({
          ...prevState,
          loadingFile: false,
          success: false,
          error: true,
          errorMessage: 'Um erro ocorreu ao adicionar um novo arquivo.',
        }));
      }
    });
  };

  const handleSaveContent = () => {
    if (categoryOption === null) {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Selecione uma categoria.',
      }));
      return;
    }

    if (states.locale === '') {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Selecione uma localidade.',
      }));
      return;
    }

    if (states.title === '') {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Insira um título.',
      }));
      return;
    }

    if (states.description === '') {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Insira uma descrição.',
      }));
      return;
    }

    if (states.documents.length === 0) {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Insira ao menos 1 arquivo.',
      }));
      return;
    }

    setStates(prevState => ({ ...prevState, success: false }));

    saveContent({ ...states, category_id: categoryOption }).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Conteúdo editado com sucesso!');
        setShowContentModal(prevState => ({ ...prevState, showNewContentModal: false }));
        setTimeout(() => window.location.reload(), 1400);
      },
      error => {
        setShowError(true);
        setTextErrorAlert('Categoria ou título não encontrado.');
        setShowContentModal(prevState => ({ ...prevState, showNewContentModal: false }));
      }
    );
  };

  const handleEditContent = () => {
    if (states.title === '') {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Insira um título.',
      }));
      return;
    }

    if (states.description === '') {
      setStates(prevState => ({
        ...prevState,
        success: false,
        error: true,
        errorMessage: 'Insira uma descrição.',
      }));
      return;
    }

    setStates(prevState => ({ ...prevState, success: false }));

    editContent({ ...states, category_id: categoryOption }).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Conteúdo editado com sucesso!');
        setShowContentModal(prevState => ({ ...prevState, showNewContentModal: false }));
        setTimeout(() => window.location.reload(), 1400);
      },
      error => {
        setShowError(true);
        setTextErrorAlert('Categoria ou título não encontrado.');
        setShowContentModal(prevState => ({ ...prevState, showNewContentModal: false }));
      }
    );
  };

  return (
    <>
      {states.loading ? (
        <Loading />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Switch
                checked={states.premium}
                onChange={e => {
                  setStates(prevState => ({ ...prevState, premium: e.target.checked }));
                }}
              />
            }
            label="Premium"
          />
          <Box sx={{ margin: '24px 0' }}>
            <SelectCategory
              optionsItems={states.categories}
              setIdValue={setCategoryOption}
              selectCategoryLabel={selectCategoryLabel} 
              setSelectCategoryLabel={setSelectCategoryLabel}
            />
          </Box>
          <Box sx={{ marginBottom: '24px' }}>
            <Select
              value={states.locale || 'Localização'}
              setValue={value =>
                setStates(prevState => ({ ...prevState, locale: value }))
              }
              optionsItems={[
                { slug: 'Português Brasil', value: 'pt-BR' },
                { slug: 'Espanhol', value: 'es' },
              ]}
            />
          </Box>
          <Input
            required
            type="text"
            id="content-title"
            name="content-title"
            label="Título"
            value={states.title}
            onChange={e =>
              setStates(prevState => ({ ...prevState, title: e.target.value }))
            }
          />
          <TextField
            label="Conteúdo"
            value={states.description}
            onChange={e =>
              setStates(prevState => ({ ...prevState, description: e.target.value }))
            }
            required={true}
          />
          {states.id === undefined && states.mobileView ? (
            <FileButton style={{ marginTop: ' 24px' }} onChange={e => uploadFile(e)} />
          ) : (
            <FileInput
              loading={states.loadingFile}
              fileName={states.fileName}
              onChange={e => uploadFile(e)}
            />
          )}
          {states.error && (
            <Stack sx={{ width: '100%', marginTop: '10px' }}>
              <Alert severity="error">{states.errorMessage}</Alert>
            </Stack>
          )}
          {states.success && (
            <Stack sx={{ width: '100%', marginTop: '10px' }}>
              <Alert severity="success">{states.successMessage}</Alert>
            </Stack>
          )}
          {states.id === undefined ? (
            <Button
              type="button"
              variant="contained"
              onClick={handleSaveContent}
              className="btns"
              sx={{
                alignSelf: 'end',
                fontWeight: 600,
                width: '150px',
                height: '44px',
                borderRadius: '10px',
                backgroundColor: 'primary.main',
                margin: '20px 16px 0',
              }}
            >
              Salvar
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              onClick={handleEditContent}
              className="btns"
              sx={{
                alignSelf: 'end',
                fontWeight: 600,
                width: '150px',
                height: '44px',
                borderRadius: '10px',
                backgroundColor: 'primary.main',
                margin: '20px 16px 0',
              }}
            >
              Salvar
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default ContentForm;
