import React, { useEffect, useState } from 'react';
import { Container, Button, Box, Link, Typography, Stack, Alert } from '@mui/material';
import Input from '../../components/Input';
import logoBlue from '../../assets/images/logo-blue.svg';
import { recoverPassword } from '../../services/users.js';
import InputPassword from '../../components/Input/inputPassword';
import { AlertError, AlertSuccess } from '../../components/Alerts';
import { useAlert } from '../../context';

const RecoverPassword = () => {
  const [mobileView, setMobileView] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
    token: '',
    password_confirm: '',
    errorPassword: false,
  });

  const { setShowSuccess, setShowError } = useAlert();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600 ? setMobileView(true) : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const handleRecoverPassword = e => {
    e.preventDefault();
    if (
      form.password_confirm === '' ||
      form.password === '' ||
      form.password !== form.password_confirm
    ) {
      setForm(prevState => ({ ...prevState, errorPassword: true }));
      return;
    }

    setForm(prevState => ({ ...prevState, errorPassword: false }));
    recoverPassword(form).then(
      response => {
        setShowSuccess(true);
        setForm({ success: true, error: false });
        setTimeout(() => (window.location.href = '/'), 3000);
      },
      error => {
        setShowError(true);
      }
    );
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          '@media (max-width: 600px)': {
            backgroundColor: 'transparent',
          },
        }}
        className={mobileView ? '' : 'border-blue'}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            overflow: 'hidden',
            width: '100%',
            minHeight: '600px',
            '@media (max-width: 600px)': {
              backgroundColor: 'white',
              minHeight: '100vh',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              backgroundColor: 'secondary.main',
              '@media (max-width: 600px)': {
                maxHeight: '200px',
              },
            }}
            className="logo-blue-container"
          >
            <img src={logoBlue} alt="Logo da empresa HWater" />
          </Box>
          <Box
            sx={{
              padding: '50px 0',
              backgroundColor: 'white',
              flexGrow: 1,
              '@media (max-width: 680px)': {
                paddingTop: '20px',
              },
            }}
          >
            <Container
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                height: '100%',
              }}
            >
              <Box
                component="section"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '80%',
                  maxWidth: '500px',
                }}
              >
                <Typography variant="h4" sx={{ color: '#41474D', textAlign: 'center' }}>
                  Redefina sua senha
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ margin: '15px 0 0', textAlign: 'center', color: '#41474D' }}
                >
                  Por favor, insira no campo abaixo o código de verificação que você
                  recebeu por e-mail e redefina uma nova senha.
                </Typography>
              </Box>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: '24px',
                  maxHeight: '420px',
                  width: '65%',
                  maxWidth: '350px',
                  '@media (max-width: 1220px)': {
                    width: '75%',
                  },
                  '@media (max-width: 1990px)': {
                    width: '85%',
                  },
                  '@media (max-width: 900px)': {
                    maxWidth: '400px',
                    width: '90%',
                  },
                  '@media (max-width: 600px)': {
                    maxWidth: '400px',
                    width: '95%',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    required
                    type="text"
                    id="token"
                    label="Código"
                    name="token"
                    value={form.token}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, token: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    required
                    type="email"
                    id="email"
                    label="Email"
                    name="Email"
                    value={form.email}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, email: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <InputPassword
                    label="Senha"
                    name="password"
                    value={form.password}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, password: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <InputPassword
                    label="Confirmar senha"
                    name="confirm-password"
                    value={form.password_confirm}
                    onChange={e =>
                      setForm(prevState => ({
                        ...prevState,
                        password_confirm: e.target.value,
                      }))
                    }
                  />
                </Box>
                {form.errorPassword && (
                  <Stack sx={{ width: '100%' }}>
                    <Alert severity="error">As senhas informadas são diferentes.</Alert>
                  </Stack>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="btns"
                    onClick={handleRecoverPassword}
                    sx={{
                      alignSelf: 'center',
                      fontWeight: 600,
                      width: '250px',
                      height: '44px',
                      borderRadius: '10px',
                      margin: '10px',
                      backgroundColor: 'primary.main',
                    }}
                  >
                    Recuperar senha
                  </Button>
                  <Typography variant="body1">
                    <Link href="/">Voltar para login</Link>
                  </Typography>
                </Box>
              </Box>
              <AlertError textAlert={'Usuário inválido.'} />
              <AlertSuccess textAlert={'Nova senha criada com sucesso!'} />
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecoverPassword;
