import React from 'react';
import { Modal } from 'react-bootstrap';
import CategoryForm from '../../containers/form/CategoryForm';
import { AlertError } from '../Alerts';

import './index.scss';

const NewCategoryModal = ({ showCategoryModal, setStates }) => (
  <Modal
    fullscreen="sm-down"
    show={showCategoryModal}
    onHide={() => setStates(prevState => ({ ...prevState, showCategoryModal: false }))}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Nova Categoria</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CategoryForm setShowCategoryModal={setStates} />
    </Modal.Body>
    <AlertError textAlert={'Categoria já registrada'} />
  </Modal>
);

export default NewCategoryModal;
