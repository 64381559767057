import React, { useState, useEffect } from 'react';
import Footer from '../containers/footer';
import Header from '../containers/Header';
import Loading from '../components/loading/loading.js';
import { getUsers, deleteUser } from '../services/users.js';
import DeleteConfirmModal from '../components/modals/DeleteConfirmModal';
import UserSearch from '../components/UserSearch';
import { Box, Typography } from '@mui/material';
import UserTable from '../containers/UserTable';
import EditUserModal from '../components/modals/EditUserModal';
import { capitalizeString } from '../helpers/capitalizeString';
import { useAlert } from '../context';
import { AlertError, AlertSuccess } from '../components/Alerts';

const User = () => {
  const initialState = {
    page: 1,
    search: '',
    loading: true,
    user: {},
    showModal: false,
    showUserModal: false,
    users: [],
  };

  const [states, setStates] = useState(initialState);
  const {
    setShowError,
    setShowSuccess,
    setTextSuccessAlert,
    setTextErrorAlert,
    textSuccessAlert,
    textErrorAlert,
  } = useAlert();

  useEffect(() => {
    getUsers(states.page).then(response =>
      setStates(prevState => ({ ...prevState, users: response.data, loading: false }))
    );
  }, [states.page]);

  const editUserModal = user => {
    setStates(prevState => ({
      ...prevState,
      user: user,
      showUserModal: true,
    }));
  };

  const handleDeleteUser = () => {
    deleteUser(states.user.id).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Usuário deletado com sucesso!');
        setStates(prevState => ({ ...prevState, showModal: false }));
        setTimeout(() => window.location.reload(), 1400);
      },
      error => {
        setShowError(true);
        setTextErrorAlert('Um erro ocorreu ao deletar o usuário.');
        setStates(prevState => ({ ...prevState, showModal: false }));
      }
    );
  };

  const Modals = () => (
    <>
      <DeleteConfirmModal
        showModal={states.showModal}
        setStates={setStates}
        deleteCard={handleDeleteUser}
        title={states.user.name}
      />

      <EditUserModal
        showUserModal={states.showUserModal}
        setStates={setStates}
        user={states.user}
      />

      <AlertError textAlert={textErrorAlert} />
      <AlertSuccess textAlert={textSuccessAlert} />
    </>
  );

  const Content = () => {
    const usersFiltered =
      states.search !== '' &&
      states.users.filter(user =>
        user.name.toLowerCase().includes(states.search.toLowerCase())
      );

    if (usersFiltered.length && usersFiltered.length > 0) {
      return (
        <UserTable
          setStates={setStates}
          editUserModal={editUserModal}
          users={usersFiltered}
        />
      );
    }
    if (!usersFiltered) {
      return (
        <UserTable
          setStates={setStates}
          editUserModal={editUserModal}
          users={states.users}
        />
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '50px',
          '@media (max-width: 600px)': {
            marginTop: 0,
            marginBottom: '40px'
          }
        }}
      >
        <Typography variant="h5">
          Não há resultado para <strong>{`${capitalizeString(states.search)}`}</strong>.
        </Typography>
        <button
          className="card-button btns"
          style={{ width: '204px', marginTop: '24px', padding: '0' }}
          onClick={() => setStates(prevState => ({ ...prevState, search: '' }))}
        >
          Recarregar usúarios
        </button>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header loggedIn={true} />
      <UserSearch
        pageTitle="Usuários"
        placeholder="Busca de usuários"
        onChange={value => {
          setStates(prevState => ({ ...prevState, search: value }));
        }}
      />
      <Box
        sx={{
          borderRadius: '15px',
          padding: '0 50px',
          flexGrow: 1,
          '@media (max-width:600px)': {
            padding: '0 20px',
          }
        }}
      >
        {states.loading ? <Loading /> : <Content />}
      </Box>
      <Footer />
      <Modals />
    </Box>
  );
};

export default User;
