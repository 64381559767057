import axios from 'axios';
import {setHeader} from './services_general.js';

export function getCategories(){
  return axios.get(process.env.REACT_APP_API_URL + 'api/category', { headers: setHeader() })
}

export function getCategory(id){
  return axios.get(process.env.REACT_APP_API_URL + 'api/category?id=' + id, { headers: setHeader() })
}

export function saveCategory(content){
  return axios.post(process.env.REACT_APP_API_URL + 'api/category', content, { headers: setHeader() })
}

export function deleteCategory(id){
  return axios.delete(process.env.REACT_APP_API_URL + 'api/category?id=' + id, { headers: setHeader() })
}
