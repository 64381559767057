import React from 'react';
import InputMask from 'react-input-mask';
import maskInput from './maskInput';
import invalidateInput from './invalidateInput';
import './input.scss';

const Input = ({ label, name, type, value, onChange, required, ...other }) => (
    <div className={`material-textfield ${invalidateInput(name, value) && 'email-error'}`}>
      <InputMask
        mask={maskInput(name)}
        value={value}
        onChange={onChange}
        placeholder=" "
        name={name}
        type={type}
        required={required}
        {...other}
      />
      {required ? <label>{`${label}*`}</label> : <label>{label}</label>}
      {invalidateInput(name, value) && <span className="email-error-msg">{name} inválido.</span>}
    </div>
  );


export default Input;
