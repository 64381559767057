import React, { useEffect, useState } from 'react';
import { saveUser, updateUser } from '../../../services/users.js';
import Loading from '../../../components/loading/loading.js';
import Input from '../../../components/Input';
import {
  FormControlLabel,
  Switch,
  Box,
  Typography,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import { useAlert } from '../../../context/index.js';
import ChangePasswordForm from './ChangePassword.js';

const UserForm = props => {
  const initialState = {
    name: '',
    email: '',
    cpf: '',
    role: 'common',
    image_url: '',
    phone: '',
    company: '',
    position: '',
    is_admin: false,
    blocked: false,
    premium: false,
    errorPassword: false,
  };

  const [states, setStates] = useState(initialState);
  const [passwords, setPasswords] = useState({ password_confirm: '', password: '' });
  const { setShowError, setShowSuccess, setTextSuccessAlert, setTextErrorAlert } =
    useAlert();

  const handleSaveUser = () => {
    if (props.user === {} || props.user === undefined) {
      saveUser(states).then(
        response => {
          setStates({ ...initialState, role: 0 });
          setShowSuccess(true);
          setTextSuccessAlert('Usuário criado com sucesso!');
          props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
          setTimeout(() => window.location.reload(), 1400);
        },
        error => {
          setShowError(true);
          setTextErrorAlert('Email já registrado para outro usuário');
          props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
        }
      );
      return;
    } else {
      if (passwords.password === '' && passwords.password_confirm === '') {
        updateUser(states).then(
          response => {
            setShowSuccess(true);
            setTextSuccessAlert('Usuário editado com sucesso!');
            props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
            setTimeout(() => window.location.reload(), 1400);
          },
          error => {
            setShowError(true);
            setTextErrorAlert('Email já registrado para outro usuário');
            props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
          }
        );
        return;
      } else if (
        passwords.password_confirm === '' ||
        passwords.password !== passwords.password_confirm
      ) {
        setStates(prevState => ({ ...prevState, errorPassword: true }));
        return;
      } else {
        setStates(prevState => ({ ...prevState, errorPassword: false }));
        const updatePassword = updateUser(passwords);
        const updateUserWithoutPassword = updateUser(states);
        Promise.all([updatePassword, updateUserWithoutPassword])
          .then(response => {
            setShowSuccess(true);
            setTextSuccessAlert('Usuário editado com sucesso!');
            props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
            setTimeout(() => window.location.reload(), 1400);
          })
          .catch(error => {
            setShowError(true);
            setTextErrorAlert('Email já registrado para outro usuário');
            props.setShowUserModal(prevState => ({ ...prevState, showUserModal: false }));
          });
      }
    }
  };

  useEffect(() => {
    if (props.user !== {} && props.user !== undefined) {
      setStates(prevState => ({
        ...prevState,
        id: props.user.id,
        name: props.user.name,
        email: props.user.email,
        cpf: props.user.cpf,
        role: props.user.role === 1 ? 'admin' : 'common',
        image_url: props.user.image_url,
        phone: props.user.phone,
        company: props.user.company,
        position: props.user.position,
        is_admin: props.user.is_admin,
        blocked: props.user.blocked,
        premium: props.user.premium,
        error: false,
        success: false,
      }));
    }
  }, [props.user]);

  useEffect(() => {
    setStates(prevState => ({
      ...prevState,
      role: states.is_admin ? 'admin' : 'common',
      premium: states.is_admin ? true : prevState.premium,
    }));
  }, [states.is_admin, states.premium]);

  return (
    <Box>
      {states.loading ? (
        <Loading />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Input
                required
                type="text"
                label="Nome"
                name="Name"
                value={states.name}
                onChange={e =>
                  setStates(prevState => ({ ...prevState, name: e.target.value }))
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <Input
                required
                type="email"
                label="Email"
                name="Email"
                value={states.email}
                onChange={e =>
                  setStates(prevState => ({ ...prevState, email: e.target.value }))
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'space-between',
                width: '100%',
                marginTop: '24px',
                '@media (max-width:600px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  marginRight: '32px',
                  '@media (max-width:600px)': {
                    width: '100%',
                  },
                }}
              >
                <Input
                  type="phone"
                  label="Telefone"
                  name="Telefone"
                  value={states.phone}
                  onChange={e =>
                    setStates(prevState => ({ ...prevState, phone: e.target.value }))
                  }
                />
              </Box>
              <Box
                sx={{
                  width: '50%',
                  '@media (max-width:600px)': {
                    width: '100%',
                    marginTop: '24px',
                  },
                }}
              >
                <Input
                  type="text"
                  label="CPF"
                  name="CPF"
                  value={states.cpf}
                  onChange={e =>
                    setStates(prevState => ({ ...prevState, cpf: e.target.value }))
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <Input
                type="text"
                label="Empresa"
                name="company"
                value={states.company}
                onChange={e =>
                  setStates(prevState => ({ ...prevState, company: e.target.value }))
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <Input
                type="text"
                label="Cargo"
                name="post"
                value={states.position}
                onChange={e =>
                  setStates(prevState => ({ ...prevState, position: e.target.value }))
                }
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="h4"
              sx={{
                margin: '24px 0 15px',
                fontWeight: 400,
                fontSize: '1.6rem !important',
                lineHeight: '20px',
                '@media (max-width:600px)': {
                  fontSize: '1.4rem !important',
                },
              }}
            >
              Perfilamento
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={states.is_admin}
                  onChange={e =>
                    setStates(prevState => ({
                      ...prevState,
                      is_admin: e.target.checked,
                    }))
                  }
                />
              }
              label="Administrador"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={states.premium}
                  onChange={e => {
                    if (states.is_admin) {
                      setStates(prevState => ({
                        ...prevState,
                        premium: true,
                      }));
                      return;
                    }
                    setStates(prevState => ({
                      ...prevState,
                      premium: e.target.checked,
                    }));
                  }}
                />
              }
              label="Premium"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={states.blocked}
                  onChange={e => {
                    setStates(prevState => ({
                      ...prevState,
                      blocked: e.target.checked,
                    }));
                  }}
                />
              }
              label="Bloqueado"
            />
          </Box>
          {(props.user !== undefined) && (
            <ChangePasswordForm
              passwords={passwords}
              user={props.user}
              setPasswords={setPasswords}
            />
          )}
          {states.errorPassword && (
            <Stack sx={{ width: '100%', marginBottom: '15px' }}>
              <Alert severity="error">As senhas informadas são diferentes.</Alert>
            </Stack>
          )}
          <Button
            type="button"
            variant="contained"
            onClick={handleSaveUser}
            className="btns"
            sx={{
              alignSelf: 'end',
              fontWeight: 600,
              width: '150px',
              height: '44px',
              borderRadius: '10px',
              backgroundColor: 'primary.main',
              margin: '20px 16px 0',
            }}
          >
            Salvar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserForm;
