import React from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import TrashIcon from '../../assets/images/icons/TrashIcon.svg';
import CategoryCardIcon from '../../assets/images/CategoryCardIcon.svg';
import { isAdmin } from '../../helpers/helpers_general.js';

import './category-card-list.scss';

const CategoriesCardList = ({ categories, setCategory }) => (
  <Box className="card-container">
    {categories.map((category, index) => (
      <Box key={`${category}-${index}-B`} className="category-wrapper">
        <Card className="category-card">
              {
                isAdmin() && (
                  <Box sx={{ position: 'absolute', top: '10px', right: '10px'}}>
                  <IconButton
                  aria-label="trash"
                  onClick={() =>
                    setCategory(prevState => ({
                      ...prevState,
                      showModal: true,
                      categorySlugSelected: category.slug,
                      categoryIdSelected: category.id,
                    }))
                  }
                  >
                  <img src={TrashIcon} alt="Deletar Categoria" />
                </IconButton>
                  </Box>
              )
            }
          <Link
            href={'/waterdb/category/' + category.id}
            sx={{ textDecoration: 'none', width: '100%' }}
          >
            <CardContent
              className={`category-card-content ${!isAdmin() && 'common-user'}`}
              sx={{ padding: '10px 5px 0 10px' }}
            >
              <img src={CategoryCardIcon} alt="Icone de livros" />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '31px',
                }}
                className="category-title"
                variant="h2"
              >
                {category.slug}
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Box>
    ))}
  </Box>
);

export default CategoriesCardList;
