const emailValidate = value => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return (regex.test(value) === false);
};

const cpfValidate = value => {
  const regex = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

  return (regex.test(value) === false);
};

const phoneValidate = value => {
  const regex = /(\(?\d{2}\)?\s)?(\d{1}\s)?(\d{4,5}(?:(\-)|)\d{4})/;

  return (regex.test(value) === false);
};

export const invalidateInput = (inputName, value) => {
  if (value !== '') {
    switch (inputName) {
      case 'Email':
        return emailValidate(value);
      case 'Telefone':
        return phoneValidate(value);
      case 'CPF':
        return cpfValidate(value);
      default:
        return false;
    }
  }
  return false;
};

export default invalidateInput;
