import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import './search.scss';

const ContainerTitleSearch = ({ pageTitle, placeholder }) => {
  const [search, setSearch] = useState('');

  const handleSearch = () => {
    if (search.length > 0) {
      window.location.href = '/waterdb/search/' + search;
    }
  };

  return (
    <div className="container-title-search">
      <Typography
        variant="h1"
        sx={{
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '28px',
          lineHeight: '40px',
          color: '#3E3D3D',
          maxWidth: '50%',
          '@media (max-width: 1024px)': {
            maxWidth: '100%',
          },
        }}
      >
        {pageTitle}
      </Typography>
      <div className="search">
        <input
          placeholder={placeholder}
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && handleSearch()}
        />
        <div className="button-container">
          <IconButton onClick={handleSearch}>
            <SearchOutlinedIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ContainerTitleSearch;
