import React from 'react';
import { Modal } from 'react-bootstrap';
import UserForm from '../../containers/form/user/UserForm';
import { useAlert } from '../../context';
import { AlertError } from '../Alerts';
import './index.scss';

const EditUserModal = ({ showUserModal, setStates, user }) => {
  const { textErrorAlert } = useAlert();
  return (
  <Modal fullscreen="sm-down" show={showUserModal} onHide={() => setStates(prevState => ({ ...prevState, showUserModal: false }))}>
    <Modal.Header closeButton>
      <Modal.Title>Editar usuário</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <UserForm setShowUserModal={setStates} user={user} />
    </Modal.Body>
    <AlertError textAlert={textErrorAlert} />
  </Modal>
)};

export default EditUserModal;
