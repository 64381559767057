import React, { useEffect, useState } from 'react';
import Footer from '../containers/footer';
import Header from '../containers/Header';
import Loading from '../components/loading/loading.js';
import { getCategories, deleteCategory } from '../services/categories.js';
import { Box } from '@mui/material';
import ContainerTitleSearch from '../components/ContainerTitleSearch';
import CategoriesCardList from '../components/CategoriesCardList';
import DeleteConfirmModal from '../components/modals/DeleteConfirmModal';
import { useAlert } from '../context';
import { AlertSuccess } from '../components/Alerts';

const Home = () => {
  const initialState = {
    categoryId: 1,
    loading: true,
    page: 1,
    end: false,
    categories: [],
    categoryIdSelected: 0,
    categorySlugSelected: '',
  };

  const [states, setStates] = useState(initialState);
  const {
    setShowError,
    setShowSuccess,
    setTextSuccessAlert,
    setTextErrorAlert,
    textSuccessAlert,
  } = useAlert();

  useEffect(() => {
    getCategories().then(response =>
      setStates(prevState => ({
        ...prevState,
        categories: response.data,
        loading: false,
      }))
    );
  }, []);

  const deleteCard = () => {
    deleteCategory(states.categoryIdSelected).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Categoria deletada com sucesso!');
        setTimeout(() => window.location.reload(), 1500);
      },
      error => {
        setShowError(true);
        setTextErrorAlert('Um erro ocorreu ao deletar a categoria.');
      }
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header loggedIn={true} />
      <ContainerTitleSearch pageTitle="Categorias" placeholder="Buscar arquivos" />
      <Box
        sx={{
          borderRadius: '15px',
          padding: '0 45px',
          flexGrow: 1,
          '@media (max-width: 920px)': {
            padding: '0 35px',
          },
        }}
      >
        {states.loading ? (
          <Loading />
        ) : (
          <CategoriesCardList categories={states.categories} setCategory={setStates} />
        )}
      </Box>
      <Footer />
      <DeleteConfirmModal
        showModal={states.showModal}
        setStates={setStates}
        deleteCard={deleteCard}
        title={states.categorySlugSelected}
      />
      <AlertSuccess textAlert={textSuccessAlert} />
    </Box>
  );
};

export default Home;
