import React, { useState, useEffect } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import logoWhite from '../../assets/images/logo-white.svg';
import { NavDropdown } from 'react-bootstrap';
import { getCategories } from '../../services/categories.js';
import {
  getName,
  getId,
  isAdmin,
  getLocale,
  logout,
} from '../../helpers/helpers_general.js';
import { setLocale } from '../../services/services_general.js';
import NewCategoryModal from '../../components/modals/NewCategoryModal.js';
import NewContentModal from '../../components/modals/NewContentModal.js';
import NewUserModal from '../../components/modals/NewUserModal.js';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Toolbar,
  Divider,
  SwipeableDrawer,
} from '@mui/material';
import { AlertError, AlertSuccess } from '../../components/Alerts';
import { useAlert } from '../../context';
import { makeStyles } from '@mui/styles';
import './header.scss';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'primary.main',
    position: 'absolute',
    padding: '18px 50px',
    zIndex: '10 !important',
  },
}));

const Header = ({ loggedIn }) => {
  const initialState = {
    sidebar: false,
    categories: [],
    name: '',
    showNewContentModal: false,
    mobileView: false,
    id: 0,
  };

  const { header } = useStyles();
  const [states, setStates] = useState(initialState);
  const { textSuccessAlert, textErrorAlert } = useAlert();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1040
        ? setStates(prevState => ({ ...prevState, mobileView: true }))
        : setStates(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    setStates(prevState => ({ ...prevState, name: getName() }));
    setStates(prevState => ({ ...prevState, id: getId() }));
    getCategories().then(response =>
      setStates(prevState => ({ ...prevState, categories: response.data }))
    );
  }, []);

  const sidebar = open => {
    setStates(prevState => ({ ...prevState, sidebar: open }));
  };

  const Modals = () => (
    <>
      <NewContentModal
        title="Novo Conteúdo"
        showNewContentModal={states.showNewContentModal}
        setStates={setStates}
      />

      <NewCategoryModal
        showCategoryModal={states.showCategoryModal}
        setStates={setStates}
      />

      <NewUserModal showUserModal={states.showUserModal} setStates={setStates} />

      <AlertError textAlert={textErrorAlert} />
      <AlertSuccess textAlert={textSuccessAlert} />
    </>
  );

  const HeaderLinks = ({ sx, sidebarOpen }) =>
    isAdmin() && (
      <>
        <NavDropdown
          className={`nav-dropdown add ${sidebarOpen ? 'sidebar-open' : ''}`}
          title="Adicionar"
        >
          <NavDropdown.Item
            href={'#'}
            onClick={() => {
              sidebar(false);
              setStates(prevState => ({ ...prevState, showCategoryModal: true }));
            }}
          >
            {'Categoria'}
          </NavDropdown.Item>
          <NavDropdown.Item
            href={'#'}
            onClick={() => {
              sidebar(false);
              setStates(prevState => ({ ...prevState, showNewContentModal: true }));
            }}
          >
            {'Conteúdo'}
          </NavDropdown.Item>
          <NavDropdown.Item
            href={'#'}
            onClick={() => {
              sidebar(false);
              setStates(prevState => ({ ...prevState, showUserModal: true }));
            }}
          >
            {'Usuário'}
          </NavDropdown.Item>
        </NavDropdown>
        <Link sx={{ padding: '0 16px', ...sx }} href="/waterdb/user">
          Usuários
        </Link>
      </>
    );

  const displayMobile = () => {
    return (
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 !important' }}
      >
        <Link
          href="/waterdb"
          sx={{
            '@media (max-width:600px)': {
              width: '150px',
            },
          }}
        >
          <img src={logoWhite} alt="Logo da empresa HWater" />
        </Link>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
          }}
          onClick={sidebar}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={states.sidebar}
          onOpen={() => sidebar(true)}
          onClose={() => sidebar(false)}
        >
          <div
            onClick={() => sidebar(false)}
            onKeyPress={() => sidebar(false)}
            role="button"
            tabIndex={0}
          >
            <IconButton>
              <ChevronRightOutlinedIcon />
            </IconButton>
          </div>
          <Divider />
          <Box
            className={'sidebar-open'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
            }}
          >
            <NavDropdown className="nav-dropdown sidebar-open" title="Categorias">
              {states.categories.map((category, index) => (
                <NavDropdown.Item
                  key={`${category}-${index}-A`}
                  href={'/waterdb/category/' + category.id}
                >
                  {category.slug}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <HeaderLinks
              sidebarOpen="sidebar-open"
              sx={{
                cursor: 'pointer',
                borderBottom: '1px solid #E2E2E5',
                padding: '10px 20px',
                color: '#49454F',
                fontSize: '14px',
                fontWeight: 500,
                textDecoration: 'none',
              }}
            />
            <NavDropdown
              className="nav-dropdown-idiom sidebar-open"
              onSelect={key => {
                setLocale(key);
                window.location.reload();
              }}
              title={getLocale().toUpperCase()}
            >
              <NavDropdown.Item eventKey="pt-BR">
                Pt-BR
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="es">
                ES
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className={'nav-dropdown add sidebar-open'}
              title={`Olá, ${states.name}`}
            >
              <NavDropdown.Item
                href={'#'}
                onClick={() => {
                  logout();
                }}
              >
                {'Sair'}
              </NavDropdown.Item>
            </NavDropdown>
          </Box>
        </SwipeableDrawer>
        <Modals />
      </Toolbar>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 !important' }}
      >
        <Link href="/waterdb">
          <img src={logoWhite} alt="Logo da empresa HWater" />
        </Link>
        <Box
          sx={{ display: 'flex', width: '50% !important' }}
          className={`sidebar-close ${isAdmin() ? 'admin-user' : 'common-user'}`}
        >
          <NavDropdown className="nav-dropdown categories" title="Categorias">
            {states.categories.map((category, index) => (
              <NavDropdown.Item
                key={`${category}-${index}-A`}
                href={'/waterdb/category/' + category.id}
              >
                {category.slug}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <HeaderLinks sx={{ color: '#FFFFFF', fontSize: '1rem', fontWeight: 500 }} />
          <NavDropdown
            className="nav-dropdown-idiom idiom"
            onSelect={key => {
              setLocale(key);
              window.location.reload();
            }}
            title={getLocale().toUpperCase()}
          >
            <NavDropdown.Item eventKey="pt-BR">
              Pt-BR
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="es">
              ES
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
              className={'nav-dropdown add'}
              title={`Olá, ${states.name}`}
            >
              <NavDropdown.Item
                href={'/'}
                onClick={() => {
                  logout();
                }}
              >
                {'Sair'}
              </NavDropdown.Item>
            </NavDropdown>
        </Box>
        <Modals />
      </Toolbar>
    );
  };

  return (
    <AppBar className={header}>
      {states.mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
};

export default Header;
