import React, { useEffect, useState } from 'react';
import TrashIcon from '../../assets/images/icons/TrashIcon.svg';
import FileIcon from '../../assets/images/icons/FileIcon.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material';

import DeleteConfirmModal from '../modals/DeleteConfirmModal.js';
import { isAdmin } from '../../helpers/helpers_general.js';
import {
  deleteDocument,
  getDocumentsByContent,
  saveDocument,
} from '../../services/documents.js';
import { uploadFileS3 } from '../../services/file';
import Loading from '../loading/loading';
import FileButton from '../Input/fileButton';

const BookFiles = ({ contentId }) => {
  const initialState = {
    showModal: false,
    loading: true,
    error: false,
    bookFiles: [],
    loadingBookFiles: true,
    loadingFile: false,
    fileName: '',
    errorMessage: '',
  };

  const [bookStates, setBookStates] = useState(initialState);

  useEffect(() => {
    getDocumentsByContent(contentId)
      .then(response =>
        setBookStates(prevState => ({
          ...prevState,
          bookFiles: response.data,
          loadingBookFiles: true,
        }))
      )
      .finally(() =>
        setBookStates(prevState => ({ ...prevState, loadingBookFiles: false }))
      );
  }, [contentId]);

  const deleteDocumentFile = id => {
    deleteDocument(id).then(
      response => {
        getDocumentsByContent(contentId).then(response =>
          setBookStates(prevState => ({
            ...prevState,
            bookFiles: response.data,
            error: false,
          }))
        );
      },
      error => {
        getDocumentsByContent(contentId).then(response =>
          setBookStates(prevState => ({
            ...prevState,
            bookFiles: response.data,
            error: true,
            errorMessage: 'Um erro ocorreu ao deletar o arquivo.',
          }))
        );
      }
    );
    setBookStates(prevState => ({ ...prevState, showModal: false }));
  };

  const uploadFile = e => {
    setBookStates(prevState => ({ ...prevState, loadingFile: true }));
    uploadFileS3(e, bookStates.documentName).then(data => {
      if (data !== undefined) {
        setBookStates(prevState => ({
          ...prevState,
          loadingFile: true,
          fileName: data.key,
        }));
        saveDocument({
          content_id: contentId,
          file_locale: data.location,
          file_file_name: data.key,
          file_content_type: data.key.split('.')[1],
        }).then(
          r => {
            getDocumentsByContent(contentId).then(response =>
              setBookStates(prevState => ({ ...prevState, bookFiles: response.data }))
            );
            setBookStates(prevState => ({
              ...prevState,
              loadingFile: false,
              fileName: '',
              error: false,
            }));
          },
          e => {
            setBookStates(prevState => ({
              ...prevState,
              loadingFile: false,
              fileName: '',
              errorMessage: 'Um erro ocorreu ao deletar o arquivo.',
              error: true,
            }));
          }
        );
      } else {
        setBookStates(prevState => ({
          ...prevState,
          loadingFile: false,
          fileName: '',
        }));
      }
    });
  };

  return (
    <Box>
      <DeleteConfirmModal
        showModal={bookStates.showModal}
        setStates={setBookStates}
        deleteCard={() => deleteDocumentFile(bookStates.documentId)}
        title={bookStates.documentName}
      />
      <Typography
        variant="h2"
        sx={{
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#1A1C1E',
          margin: '40px 0 24px',
        }}
      >
        Arquivos
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {bookStates.loadingBookFiles ? (
          <Box sx={{ margin: '0 24px 24px', width: '50px' }}>
            <Loading />
          </Box>
        ) : bookStates.bookFiles.length === 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ fontSize: '16px' }}>
              Não há arquivos para download.
            </Typography>
          </Box>
        ) : (
          <>
            {bookStates.bookFiles.map((doc, index) => (
              <Box key={`${doc.id}-${doc.file_file_name}-${index}`} className="link-file">
                <a href={doc.file_locale} target="_blank" rel="noreferrer">
                  <Box className="file-name">
                    <img
                      alt="Icone de documento"
                      src={FileIcon}
                      className="file-icon"
                      onClick={() => {
                        setBookStates(prevState => ({
                          ...prevState,
                          showModal: false,
                          documentName: doc.file_file_name,
                          documentId: doc.id,
                        }));
                      }}
                    />
                    <span>{doc.file_file_name}</span>
                  </Box>
                  <IconButton className="download-icon">
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </a>
                {isAdmin() && (
                  <IconButton
                    className="trash-icon"
                    onClick={() => {
                      setBookStates(prevState => ({
                        ...prevState,
                        showModal: true,
                        documentName: doc.file_file_name,
                        documentId: doc.id,
                      }));
                    }}
                  >
                    <img alt="Icone de deletar" src={TrashIcon} />
                  </IconButton>
                )}
              </Box>
            ))}
          </>
        )}

        {isAdmin() && (
          <div className="add-file">
            {bookStates.error && (
              <Stack sx={{ width: '35%', margin: 'auto' }}>
                <Alert severity="error">{bookStates.errorMessage}</Alert>
              </Stack>
            )}
            <FileButton onChange={e => uploadFile(e)} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default BookFiles;
