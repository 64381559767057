import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Button, Box, Link, Typography, Alert, Stack } from '@mui/material';
import Input from '../../../components/Input';
import logoBlue from '../../../assets/images/logo-blue.svg';
import InputPassword from '../../../components/Input/inputPassword';
import '../index.scss';

const LoginForm = () => {
  const [form, setForm] = useState({ email: '', password: '', error: false });
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600 ? setMobileView(true) : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const handleLogin = event => {
    event.preventDefault();
    axios.post(process.env.REACT_APP_API_URL + 'api/authentication', form).then(
      response => {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('locale', 'pt-BR');
        window.location.href = '/waterdb';
      },
      error => {
        setForm({ ...form, error: true });
      }
    );
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          '@media (max-width: 600px)': {
            backgroundColor: 'transparent',
          },
        }}
        className={mobileView ? '' : 'border-blue'}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            overflow: 'hidden',
            width: '100%',
            minHeight: '600px',
            '@media (max-width: 600px)': {
              backgroundColor: 'white',
              minHeight: '100vh',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              backgroundColor: 'secondary.main',
              '@media (max-width: 600px)': {
                maxHeight: '200px',
              },
            }}
            className="logo-blue-container"
          >
            <img src={logoBlue} alt="Logo da empresa HWater" />
          </Box>
          <Box
            sx={{
              padding: '50px 0',
              backgroundColor: 'white',
              flexGrow: 1,
              '@media (max-width: 680px)': {
                paddingBottom: '50%',
              },
            }}
          >
            <Container
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                height: '100%',
              }}
            >
              <Typography variant="h4" sx={{ color: '#41474D' }}>
                Login
              </Typography>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '14px 0',
                }}
                className="fields"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    type="email"
                    id="email"
                    label="Email"
                    name="email"
                    value={form.email}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, email: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <InputPassword
                    name="password"
                    label="Senha"
                    id="password"
                    value={form.password}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, password: e.target.value }))
                    }
                  />
                </Box>
                {form.error && (
                  <Stack sx={{ width: '95%' }}>
                    <Alert severity="error">Email ou senha inválidos!</Alert>
                  </Stack>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleLogin}
                  className="btns"
                  sx={{
                    alignSelf: 'center',
                    fontWeight: 600,
                    width: '70%',
                    height: '44px',
                    borderRadius: '10px',
                    backgroundColor: 'primary.main',
                    marginTop: '8px',
                  }}
                >
                  Entrar
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="body1">
                  Ainda não tem cadastro? <Link href="/register">Cadastre-se</Link>
                </Typography>
                <Link href="/send_token_password">Esqueci minha senha</Link>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
