import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import logoWhite from '../../assets/images/logo-white.svg';
import { FacebookOutlined, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  contact: {
    padding: '45px 0 0 !important',
    '@media (max-width: 900px)': {
      padding: '0 0 25px 50px !important',
    },
  },
  group: {
    padding: '45px 0 0 !important',
    '@media (max-width: 600px)': {
      padding: '45px 0 25px 50px !important',
    },
  },
}));

const Footer = () => {
  const { contact, group } = useStyles();
  return (
    <footer
      style={{
        marginTop: '40px',
        position: 'relative',
        bottom: '0px',
      }}
    >
      <Box bgcolor="primary.main" color="white">
        <Box sx={{ padding: '30px 50px !important' }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  paddingBottom: '15px',
                  '@media (max-width:600px)': {
                    width: '150px',
                  },
                }}
              >
                <img src={logoWhite} alt="Logo da empresa HWater" />
              </Box>
              <Box>
                <Link
                  sx={{
                    color: 'white',
                    paddingRight: '15px',
                    '&:visited': {
                      color: 'white',
                    },
                    '&:hover, &:active': {
                      color: '#E4F2FF',
                    },
                  }}
                  target="_blank"
                  href="https://www.facebook.com/grupohwater"
                  aria-label="facebook"
                >
                  <FacebookOutlined sx={{ height: '40px', width: '40px' }} />
                </Link>
                <Link
                  sx={{
                    color: 'white',
                    paddingRight: '15px',
                    '&:visited': {
                      color: 'white',
                    },
                    '&:hover, &:active': {
                      color: '#E4F2FF',
                    },
                  }}
                  target="_blank"
                  href="https://www.instagram.com/grupohwater/"
                  aria-label="instagram"
                >
                  <Instagram sx={{ height: '40px', width: '40px' }} />
                </Link>
                <Link
                  sx={{
                    color: 'white',
                    paddingRight: '15px',
                    '&:visited': {
                      color: 'white',
                    },
                    '&:hover, &:active': {
                      color: '#E4F2FF',
                    },
                  }}
                  target="_blank"
                  href="https://www.linkedin.com/company/grupohwater/"
                  aria-label="linkedin"
                >
                  <LinkedIn sx={{ height: '40px', width: '40px' }} />
                </Link>
                {/* <Link
                sx={{ color: 'white', paddingRight: '15px', '&:visited': {
                      color: 'white',
                    },
                    '&:hover, &:active': {
                    color: '#E4F2FF'
                  } }}
                target="_blank"
                href="#"
                aria-label="youtube"
              >
                <YouTube sx={{ height: '40px', width: '40px' }} />
              </Link> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={group}>
              <Box>
                <Typography variant="h6">Grupo HWater</Typography>
                <Typography sx={{ fontSize: '0.813rem' }}>
                  {'Rua Quintino Bocaiúva, 1051 '}
                </Typography>
                <Typography sx={{ fontSize: '0.813rem' }}>
                  {'Vila Seixas - Ribeirão Preto - SP'}
                </Typography>
                <Typography sx={{ fontSize: '0.813rem' }}>{'CEP 14020-095'}</Typography>
                <Typography sx={{ fontSize: '0.813rem' }}>
                  {'+ 55 16 3610.3069'}
                </Typography>
                <Typography
                  sx={{ fontSize: '0.813rem', fontWeight: 500, paddingTop: '10px' }}
                >
                  Veja mais sobre nós:{' '}
                  <Link
                    sx={{
                      color: 'white',
                      '&:visited': {
                        color: 'white',
                      },
                      '&:hover, &:active': {
                        color: '#E4F2FF',
                      },
                    }}
                    target="_blank"
                    href="waterdb.com.br"
                  >
                    waterdb.com.br
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} className={contact}>
              <Typography variant="h6">Contato</Typography>
              <Typography paragraph sx={{ fontSize: '0.813rem' }}>
                Em caso de dúvidas, reclamações ou feedbacks, entre em contato conosco.
                Teremos o maior prazer em atender sua solicitação.
              </Typography>
              <Typography sx={{ fontSize: '0.813rem', fontWeight: 500 }}>
                Basta enviar um e-mail para: contato@waterdb.com.br{' '}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
