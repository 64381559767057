import React, { useEffect, useState } from 'react';
import { Container, Button, Box, Typography, Stack, Alert, Link } from '@mui/material';
import { saveUser } from '../../services/users.js';
import logoBlue from '../../assets/images/logo-blue.svg';
import Input from '../../components/Input';
import InputPassword from '../../components/Input/inputPassword.js';
import { AlertError, AlertSuccess } from '../../components/Alerts';
import { useAlert } from '../../context';

import './index.scss';

const RegisterForm = () => {
  const initialState = {
    name: '',
    email: '',
    cpf: '',
    role: 'common',
    image_url: '',
    phone: '',
    company: '',
    position: '',
    password_confirm: '',
    password: '',
    is_admin: false,
    blocked: false,
    premium: false,
    errorPassword: false,
  };

  const [form, setForm] = useState(initialState);
  const [mobileView, setMobileView] = useState(false);

  const { setShowSuccess, setShowError } = useAlert();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600 ? setMobileView(true) : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const handleRegister = e => {
    e.preventDefault();
    if (
      form.password_confirm === '' ||
      form.password === '' ||
      form.password !== form.password_confirm
    ) {
      setForm(prevState => ({ ...prevState, errorPassword: true }));
      return;
    }

    setForm(prevState => ({ ...prevState, errorPassword: false }));
    saveUser(form).then(
      response => {
        setShowSuccess(true);
        setForm(prevState => ({ ...prevState, errorPassword: false }));
        setTimeout(() => (window.location.href = '/'), 3000);
      },
      error => {
        setShowError(true);
      }
    );
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          '@media (max-width: 600px)': {
            backgroundColor: 'transparent',
          },
        }}
        className={mobileView ? '' : 'border-blue'}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            overflow: 'hidden',
            width: '100%',
            '@media (max-width: 600px)': {
              backgroundColor: 'white',
              minHeight: '100vh',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              backgroundColor: 'secondary.main',
              '@media (max-width: 600px)': {
                minHeight: '200px',
              },
              '@media (min-width: 680px) and (max-width: 900px)': {
                maxWidth: '320px',
              },
            }}
            className="logo-blue-container"
          >
            <img src={logoBlue} alt="Logo da empresa HWater" />
          </Box>
          <Box
            sx={{
              padding: '50px 0',
              backgroundColor: 'white',
              flexGrow: 1,
              '@media (max-width: 680px)': {
                paddingTop: '20px',
              },
            }}
          >
            <Container
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              <Typography variant="h4" sx={{ color: '#41474D' }}>
                Crie sua conta
              </Typography>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: '24px',
                  maxHeight: '720px',
                  width: '65%',
                  '@media (max-width: 1220px)': {
                    width: '75%',
                  },
                  '@media (max-width: 1990px)': {
                    width: '85%',
                  },
                  '@media (max-width: 900px)': {
                    maxWidth: '400px',
                    width: '90%',
                  },
                  '@media (max-width: 600px)': {
                    maxWidth: '400px',
                    width: '95%',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    required
                    type="text"
                    label="Nome"
                    name="Name"
                    value={form.name}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, name: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    required
                    type="email"
                    label="Email"
                    name="Email"
                    value={form.email}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, email: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    type="phone"
                    label="Telefone"
                    name="Telefone"
                    value={form.phone}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, phone: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    type="text"
                    label="CPF"
                    name="CPF"
                    value={form.cpf}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, cpf: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    type="text"
                    label="Empresa"
                    name="company"
                    value={form.company}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, company: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <Input
                    type="text"
                    label="Cargo"
                    name="post"
                    value={form.position}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, position: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    width: '100%',
                  }}
                >
                  <InputPassword
                    label="Senha"
                    name="password"
                    value={form.password}
                    onChange={e =>
                      setForm(prevState => ({ ...prevState, password: e.target.value }))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0 15px',
                    width: '100%',
                  }}
                >
                  <InputPassword
                    label="Confirmar senha"
                    name="confirm-password"
                    value={form.password_confirm}
                    onChange={e => {
                      setForm(prevState => ({
                        ...prevState,
                        password_confirm: e.target.value,
                      }));
                    }}
                  />
                </Box>
                {form.errorPassword && (
                  <Stack sx={{ width: '100%', marginBottom: '15px' }}>
                    <Alert severity="error">As senhas informadas são diferentes.</Alert>
                  </Stack>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="btns"
                    onClick={handleRegister}
                    sx={{
                      alignSelf: 'center',
                      fontWeight: 600,
                      width: '250px',
                      height: '44px',
                      borderRadius: '10px',
                      margin: '10px',
                      backgroundColor: 'primary.main',
                    }}
                  >
                    Criar conta
                  </Button>
                  <Typography variant="body1">
                    <Link href="/">Voltar para login</Link>
                  </Typography>
                </Box>
              </Box>
              <AlertError textAlert={'Este e-mail já está cadastrado.'} />
              <AlertSuccess textAlert={'E-mail cadastrado com sucesso!'} />
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterForm;
