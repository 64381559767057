import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import InputPassword from '../../../components/Input/inputPassword.js';

const ChangePasswordForm = ({ user, setPasswords, passwords }) => {
  useEffect(() => {
    if (user !== {} && user !== undefined) {
      setPasswords(prevState => ({
        ...prevState,
        id: user.id,
        email: user.email,
      }));
    }
  }, [user, setPasswords]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '24px',
      }}
    >
      <Typography
              variant="h4"
              sx={{
                alignSelf: 'flex-start',
                marginBottom: '15px',
                fontWeight: 400,
                fontSize: '1.6rem !important',
                lineHeight: '20px',
                '@media (max-width:600px)': {
                  fontSize: '1.4rem !important',
                }
              }}
            >
              Redefinição de senha
            </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px 0',
          width: '100%',
        }}
      >
        <InputPassword
          label="Nova Senha"
          name="password"
          value={passwords.password}
          onChange={e =>
            setPasswords(prevState => ({ ...prevState, password: e.target.value }))
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px 0',
          width: '100%',
        }}
      >
        <InputPassword
          label="Confirmar nova senha"
          name="confirm-password"
          value={passwords.password_confirm}
          onChange={e =>
            setPasswords(prevState => ({
              ...prevState,
              password_confirm: e.target.value,
            }))
          }
        />
      </Box>
    </Box>
  );
};

export default ChangePasswordForm;
