export function setHeader(){
  const header = {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('authToken')
    }

    return header
}

export function getLocale(){
  return localStorage.getItem('locale')
}

export function setLocale(locale='pt-BR'){
  return localStorage.setItem('locale', locale)
}
