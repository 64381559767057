import React, { useState } from 'react';
import { saveCategory } from '../../../services/categories.js';
import Input from '../../../components/Input';
import { Box, Button } from '@mui/material';
import { useAlert } from '../../../context';

const CategoryForm = ({ setShowCategoryModal }) => {
  const [states, setStates] = useState({
    slug: '',
  });

  const { setShowError, setShowSuccess, setTextSuccessAlert } = useAlert();

  const handleSaveCategory = () => {
    saveCategory(states).then(
      response => {
        setShowSuccess(true);
        setTextSuccessAlert('Categoria criada com sucesso');
        setShowCategoryModal(prevState => ({ ...prevState, showCategoryModal: false }));
        setTimeout(() => window.location.reload(), 1400);
      },
      error => {
        setShowError(true);
        setShowCategoryModal(prevState => ({ ...prevState, showCategoryModal: false }));
      }
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        required
        type="text"
        id="category-title"
        name="category-title"
        label="Título"
        value={states.slug}
        onKeyDown={e => e.key === 'Enter' && handleSaveCategory()}
        onChange={e => setStates(prevState => ({ ...prevState, slug: e.target.value }))}
      />
      <Button
        type="button"
        variant="contained"
        className="btns"
        onClick={handleSaveCategory}
        sx={{
          alignSelf: 'end',
          fontWeight: 600,
          width: '150px',
          height: '44px',
          borderRadius: '10px',
          backgroundColor: 'primary.main',
          margin: '20px 16px 0',
        }}
      >
        Salvar
      </Button>
    </Box>
  );
};

export default CategoryForm;
