import S3 from 'react-aws-s3';

function setConfigurationS3(bucket_name){
  return {
    bucketName: bucket_name,
    region: 'sa-east-1',
    accessKeyId: 'AKIAWLWV363XZ2IPVIUR',
    secretAccessKey: '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'
  }
}

export function uploadFileS3(file, name){
  let ReactS3Client = new S3(setConfigurationS3('waterdatabase-files-prd'));
  name = name + Date.now()
  return ReactS3Client
          .uploadFile(file.target.files[0], file.target.files[0].name)
          .then(data => {return data})
          .catch(err => {return undefined})
}

export function uploadImageS3(file){
  let ReactS3Client = new S3(setConfigurationS3('waterdatabase-images-prd'));

  return ReactS3Client
          .uploadFile(file.target.files[0], file.target.files[0].name + Date.now())
          .then(data => {return data})
          .catch(err => {return undefined})
}
