import React from 'react';
import { Modal } from 'react-bootstrap';
import ContentForm from '../../containers/form/ContentForm';
import { useAlert } from '../../context';
import { AlertError } from '../Alerts';

import './index.scss';

const NewContentModal = ({ showNewContentModal, setStates, card, categoryId, title }) => {
  const { textErrorAlert } = useAlert();
  return (
    <Modal
      fullscreen="sm-down"
      show={showNewContentModal}
      onHide={() =>
        setStates(prevState => ({ ...prevState, showNewContentModal: false }))
      }
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContentForm setShowContentModal={setStates} card={card} categoryId={categoryId} />
      </Modal.Body>
      <AlertError  textAlert={textErrorAlert} />
    </Modal>
  );
};

export default NewContentModal;
