import React, { useEffect } from 'react';
import RegisterForm from '../containers/form/RegisterForm.js';

import { isLoggedIn, logout } from '../helpers/helpers_general.js';

const Register = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <>
      <RegisterForm />
    </>
  );
};

export default Register;
