const maskInput = type => {
  switch (type) {
    case 'CPF':
      return '999.999.999-99';
    case 'Telefone':
      return '(99) 9 9999-9999';
    default:
      return '';
  }
};

export default maskInput;
