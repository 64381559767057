import React, { useEffect, useRef, useState } from 'react';

import './textField.scss';

const TextField = ({ label, value, onChange, required, ...other }) => { 
  const textAreaRef = useRef(null);
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const [parentHeight, setParentHeight] = useState("auto");

	useEffect(() => {
		setParentHeight(`${textAreaRef.current.scrollHeight}px`);
		setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
	}, [value]);

	const onChangeHandler = (e) => {
		setTextAreaHeight("auto");
		setParentHeight(`${textAreaRef.current.scrollHeight}px`);
		onChange(e);
	};

  return (
  <div className="input-textarea-container">
    <div className="material-textfield" style={{ minHeight: parentHeight }}>
      <textarea
        value={value}
        onChange={onChangeHandler}
        placeholder=" "
        type="text"
        ref={textAreaRef}
        name="textarea-field"
        rows={1}
        style={{
					height: textAreaHeight,
				}}
        {...other}
      />
      {required ? <label>{`${label}*`}</label> : <label>{label}</label>}
    </div>
  </div>
)};

export default TextField;
