import React from 'react';
import { Box } from '@mui/material';
import { Modal } from 'react-bootstrap';
import BookFiles from './book_files.js';

import './book.scss';

const BookInformation = ({ contentId, title, description, showContentModal, setModalStates }) => {
  return (
    <>
      <Modal
        fullscreen="md-down"
        dialogClassName="modal-900px"
        show={showContentModal}
        onHide={() => setModalStates(prevState => ({ ...prevState, showContentModal: false }))}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box className="modal-description">{description}</Box>
          <BookFiles contentId={contentId}  />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookInformation;
