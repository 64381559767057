import axios from 'axios';
import {setHeader} from './services_general.js';
import {getLocale} from '../helpers/helpers_general.js';

export function getContents(page=1, locale){
  locale = getLocale()
  return axios.get(process.env.REACT_APP_API_URL + 'api/content?page=' + page + '&locale=' + locale, { headers: setHeader() })
}

export function getContentsByCategory(categoryId, page, locale){
  locale = getLocale()
  return axios.get(process.env.REACT_APP_API_URL + 'api/content?category_id=' + categoryId + '&page=' + page+ '&locale=' + locale, { headers: setHeader() })
}

export function getContentsBySearch(search, page, locale){
  locale = getLocale()
  return axios.get(process.env.REACT_APP_API_URL + 'api/content?search=' + search + '&page=' + page+ '&locale=' + locale, { headers: setHeader() })
}

export function saveContent(content){
  return axios.post(process.env.REACT_APP_API_URL + 'api/content', content, { headers: setHeader() })
}

export function editContent(content){
  return axios.put(process.env.REACT_APP_API_URL + 'api/content', content, { headers: setHeader() })
}

export function deleteContent(id){
  return axios.delete(process.env.REACT_APP_API_URL + 'api/content?id=' + id, { headers: setHeader() })
}
