import React from 'react';
import LoadImage from '../../assets/images/icons/load.gif';

import './input.scss';

const FileInput = ({onChange, fileName, loading}) => {

  const fileInput = React.useRef();

  return (
    <>
    <div className='file-input-container'>
      <button 
        onClick={e => fileInput.current && fileInput.current.click()}
        className="file-input-button"
      >
        Escolher arquivo
      </button>

      <input 
        ref={fileInput} 
        type="file" 
        style={{ display: 'none' }} 
        onChange={onChange}
        id="inputFile"
      />
      <div className="file-input-label">
        <label htmlFor="inputFile">{fileName ? fileName : 'Nenhum arquivo escolhido.'}</label>
        {loading && <img  alt="imagem de carregamento" src={LoadImage} />}
      </div>
    </div>
    </>
  );
}

export default FileInput;
