import React from 'react';
import { Modal } from 'react-bootstrap';
import UserForm from '../../containers/form/user/UserForm';
import { useAlert } from '../../context';
import { AlertError } from '../Alerts';

import './index.scss';

const NewUserModal = ({ showUserModal, setStates }) => {
  const { textErrorAlert } = useAlert();
  return (
  <Modal
      fullscreen="sm-down"
      show={showUserModal}
      onHide={() => setStates(prevState => ({ ...prevState, showUserModal: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title>Novo Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm setShowUserModal={setStates} />
      </Modal.Body>
    <AlertError textAlert={textErrorAlert} />
  </Modal>
)};

export default NewUserModal;
