import React, { Component } from 'react';
import Footer from '../containers/footer';
import Header from '../containers/Header';
import { Container, Row } from 'react-bootstrap';

import { getUserById } from '../services/users.js';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      userId: 0,
      user: {},
    };
  }

  componentWillMount() {
    getUserById(this.props.match.params.id).then(response =>
      this.setState(state => {
        return { user: response.data };
      })
    );
  }

  render() {
    return (
      <>
        <Header loggedIn={true} />
        <div className="content" style={{ marginTop: '100px' }}>
          <Container
            style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              padding: '15px',
              marginTop: '150px',
            }}
          >
            <Row></Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Profile;
