import React from 'react';
import { Alert, AlertTitle, Snackbar, Stack } from '@mui/material';
import { useAlert } from '../../context';

export const AlertSuccess = ({ textAlert }) => {
  const { showSuccess, setShowSuccess, setTextSuccessAlert } = useAlert();

  const onCloseAlert = () => {
    setShowSuccess(false);
    setTextSuccessAlert('');
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showSuccess}
      autoHideDuration={2000}
      onClose={onCloseAlert}
    >
      <Stack sx={{ marginTop: '10px' }}>
        <Alert onClose={onCloseAlert} severity="success">
          <AlertTitle>Sucesso</AlertTitle>
          {textAlert}
        </Alert>
      </Stack>
    </Snackbar>
  );
};

export const AlertError = ({ textAlert }) => {
  const { showError, setShowError, setTextErrorAlert } = useAlert();

  const onCloseAlert = () => {
    setShowError(false);
    setTextErrorAlert('');
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showError}
      autoHideDuration={2000}
      onClose={onCloseAlert}
    >
      <Stack sx={{ marginTop: '10px' }}>
        <Alert onClose={onCloseAlert} severity="error">
          <AlertTitle>Erro</AlertTitle>
          {textAlert}
        </Alert>
      </Stack>
    </Snackbar>
  );
};
