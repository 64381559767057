import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Typography } from '@mui/material';
import TrashBlueIcon from '../../assets/images/icons/TrashBlueIcon.svg';
import EditBlueIcon from '../../assets/images/icons/EditBlueIcon.svg';

import './userTable.scss';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: '#000000',
    borderBottom: 'none',
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    width: '28%',
    borderLeft: 'none',
  },
  [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
    width: '25%',
  },
  [`&.${tableCellClasses.head}:nth-of-type(3)`]: {
    width: '17%',
  },
  [`&.${tableCellClasses.head}:nth-of-type(4)`]: {
    width: '15%',
  },
  [`&.${tableCellClasses.head}:last-child`]: {
    width: '15%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  th: {
    borderBottom: 'none',
  },
  td: {
    borderBottom: 'none',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#F4F6FF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
}));

const createData = (name, email, accessType, accountType, others) => ({
  name,
  email,
  accessType,
  accountType,
  ...others,
});

export const UserTableDesktop = ({ users, editUserModal, setStates }) => {
  const rows = users.map(user => {
    const accessType = user.is_admin ? 'Administrador' : 'Comum';
    const accountType = user.premium ? 'Premium' : 'Normal';
    return createData(user.name, user.email, accessType, accountType, user);
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700, margin: 0 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Nome do usuário</StyledTableCell>
            <StyledTableCell align="center">Email do usuário</StyledTableCell>
            <StyledTableCell align="center" size="small">
              Tipo de Acesso
            </StyledTableCell>
            <StyledTableCell align="center" size="small">
              Tipo de Conta
            </StyledTableCell>
            <StyledTableCell align="center" size="small">
              Edição / Exclusão
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={`${row.name}-${row.email}-${index}`}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center" size="small">
                {row.accessType}
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                {row.accountType}
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                <IconButton
                  aria-label="edit"
                  sx={{ marginRight: '20px' }}
                  onClick={() => editUserModal(row)}
                >
                  <img src={EditBlueIcon} alt="Editar Usuário" />
                </IconButton>
                <IconButton
                  aria-label="trash"
                  onClick={() =>
                    setStates(prevState => ({ ...prevState, user: row, showModal: true }))
                  }
                >
                  <img src={TrashBlueIcon} alt="Deletar Usuário" />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const UserTableMobile = ({ users, editUserModal, setStates }) => {
  const rows = users.map(user => {
    const accessType = user.is_admin ? 'Administrador' : 'Comum';
    const accountType = user.premium ? 'Premium' : 'Normal';
    return createData(user.name, user.email, accessType, accountType, user);
  });

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        color: '#000000',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 100, 148, 0.25))',
      }}
      className="user-table-mobile"
    >
      <Box sx={{ margin: 0 }}>
        <Box sx={{ borderBottom: '0.7px solid #8BCEFF', padding: '16px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>
            Informações pessoais
          </Typography>
        </Box>
        <Box>
          {rows.map((row, index) => (
            <Box
              key={`${row.name}-${row.email}-${index}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                padding: '10px 16px',
                borderBottom: '0.7px solid #8BCEFF',
              }}
            >
              <Box sx={{ marginRight: '12px' }}>
                <Typography sx={{ fontSize: '14px', padding: '5px 0' }}>
                  <strong>Nome:</strong> {row.name}
                </Typography>
                <Typography sx={{ fontSize: '14px', padding: '5px 0' }}>
                  <strong>Email:</strong> {row.email}
                </Typography>
                <Typography sx={{ fontSize: '14px', padding: '5px 0' }}>
                  <strong>Tipo de Acesso:</strong> {row.accessType}
                </Typography>
                <Typography sx={{ fontSize: '14px', padding: '5px 0' }}>
                  <strong>Tipo de Conta:</strong> {row.accountType}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  '@media (max-width: 500px)': {
                    position: 'relative',
                    marginBottom: '5px',
                  },
                }}
              >
                <IconButton
                  aria-label="edit"
                  sx={{
                    marginRight: '20px',
                    '@media (max-width: 500px)': {
                      marginRight: '10px',
                    },
                  }}
                  onClick={() => editUserModal(row)}
                >
                  <img src={EditBlueIcon} alt="Editar Usuário" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#006494',
                      marginLeft: '8px',
                      '@media (min-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Editar
                  </Typography>
                </IconButton>
                <IconButton
                  aria-label="trash"
                  onClick={() =>
                    setStates(prevState => ({ ...prevState, user: row, showModal: true }))
                  }
                >
                  <img src={TrashBlueIcon} alt="Deletar Usuário" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#006494',
                      marginLeft: '8px',
                      '@media (min-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Deletar
                  </Typography>
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const UserTable = ({ users, editUserModal, setStates }) => {
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 990 ? setMobileView(true) : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  return (
    <>
      {mobileView ? (
        <UserTableMobile
          users={users}
          editUserModal={editUserModal}
          setStates={setStates}
        />
      ) : (
        <UserTableDesktop
          users={users}
          editUserModal={editUserModal}
          setStates={setStates}
        />
      )}
    </>
  );
};

export default UserTable;
