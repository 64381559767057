import React, { useState, useContext } from 'react';

const AlertContext = React.createContext();

export const useAlert = () => {
  const {
    showError,
    setShowError,
    showSuccess,
    setShowSuccess,
    textErrorAlert,
    textSuccessAlert,
    setTextErrorAlert,
    setTextSuccessAlert
  } = useContext(AlertContext);

  return {
    showError,
    setShowError,
    showSuccess,
    setShowSuccess,
    textErrorAlert,
    textSuccessAlert,
    setTextErrorAlert,
    setTextSuccessAlert
  };
};

const AlertProvider = ({ children }) => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [textErrorAlert, setTextErrorAlert] = useState('');
  const [textSuccessAlert, setTextSuccessAlert] = useState('');

  return (
    <AlertContext.Provider
      value={{
        showError,
        setShowError,
        showSuccess,
        setShowSuccess,
        textErrorAlert,
        textSuccessAlert,
        setTextErrorAlert,
        setTextSuccessAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
