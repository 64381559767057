import axios from 'axios';
import {setHeader} from './services_general.js';

export function getUsers(){
  return axios.get(process.env.REACT_APP_API_URL + 'api/user', { headers: setHeader() })
}

export function getUserById(id){
  return axios.get(process.env.REACT_APP_API_URL + 'api/user?id=' + id, { headers: setHeader() })
}

export function saveUser(content){
  return axios.post(process.env.REACT_APP_API_URL + 'api/user', content)
}

export function updateUser(content){
  return axios.put(process.env.REACT_APP_API_URL + 'api/user', content, { headers: setHeader() })
}

export function recoverPasswordSendMail(email){
  return axios.get(process.env.REACT_APP_API_URL + 'api/recover_password?email=' + email, { headers: setHeader() })
}

export function recoverPassword(content){
  return axios.post(process.env.REACT_APP_API_URL + 'api/recover_password', content, { headers: setHeader() })
}

export function deleteUser(id){
  return axios.delete(process.env.REACT_APP_API_URL + 'api/user?id=' + id, { headers: setHeader() })
}
