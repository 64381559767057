import React, { useEffect } from 'react';

import RecoverPasswordWithoutTokenForm from '../containers/form/RecoverPasswordWithoutTokenForm';

import { isLoggedIn, logout } from '../helpers/helpers_general.js';

const RecoverPasswordWithoutToken = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <>
      <RecoverPasswordWithoutTokenForm />
    </>
  );
};

export default RecoverPasswordWithoutToken;
