import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import './search.scss';

const UserSearch = ({ pageTitle, placeholder, onChange }) => {
  const [searchValue, setSearch] = useState('');

  const handleClick = () => {
    setSearch('');
    onChange(searchValue);
  };

  return (
    <div className="container-user-search">
      <Typography
        variant="h1"
        sx={{
          fontWeight: 500,
          fontSize: '28px',
          lineHeight: '40px',
          color: '#3E3D3D',
        }}
      >
        {pageTitle}
      </Typography>
      <div className="search">
        <input
          placeholder={placeholder}
          value={searchValue}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && handleClick()}
        />
        <div className="button-container">
          <IconButton onClick={handleClick}>
            <SearchOutlinedIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
