import jwt_decode from "jwt-decode";



export function logout(){
  localStorage.removeItem('authToken');
}

export function isLoggedIn(){
  let token = localStorage.getItem('authToken');
  if (token) {
    let decoded = jwt_decode(token);
    const nowDate = new Date().getTime() / 1000;

    if (nowDate > decoded.exp) {
      logout();
    }
    if (decoded !== undefined) {
      return true
    }
  }
    return false
}

export function isAdmin(){
  let token = localStorage.getItem('authToken');
  if (token) {
    let decoded = jwt_decode(token);
    const nowDate = new Date().getTime() / 1000;

    if (nowDate > decoded.exp) {
      logout();
    }
    if (decoded !== undefined) {
      if (decoded.sub.role === 1 || decoded.sub.role === '1') {
        return true
      }
    }
  }
    return false
}


export function getName(){
  let token = localStorage.getItem('authToken');
  if (token) {
    let decoded = jwt_decode(token);
    if (decoded !== undefined) {
      return decoded.sub.name
    }
  }
    return ''
}

export function getId(){
  let token = localStorage.getItem('authToken');
  if (token) {
    let decoded = jwt_decode(token);
    if (decoded !== undefined) {
      return decoded.sub.id
    }
  }
    return 0
}

export function getLocale(){
  return localStorage.getItem('locale')
}
