import React, { useEffect } from 'react';
import RecoverPasswordForm from '../containers/form/RecoverPasswordForm';

import { isLoggedIn, logout } from '../helpers/helpers_general.js';

const RecoverPassword = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <>
      <RecoverPasswordForm />
    </>
  );
};

export default RecoverPassword;
