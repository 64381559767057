import React, { useEffect } from 'react';
import SendTokenPasswordForm from '../containers/form/SendTokenPasswordForm';

import { isLoggedIn, logout } from '../helpers/helpers_general.js';

const SendTokenPassword = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <>
      <SendTokenPasswordForm />
    </>
  );
};

export default SendTokenPassword;
