import axios from 'axios';
import {setHeader} from './services_general.js';

export function getDocumentsByContent(contentId){
  return axios.get(process.env.REACT_APP_API_URL + 'api/document?content_id=' + contentId, { headers: setHeader() })
}

export function saveDocument(content){
  return axios.post(process.env.REACT_APP_API_URL + 'api/document', content, { headers: setHeader() })
}

export function deleteDocument(id){
  return axios.delete(process.env.REACT_APP_API_URL + 'api/document?id=' + id, { headers: setHeader() })
}
