import React, { useEffect } from 'react';
import LoginForm from '../containers/form/LoginForm';

import { isLoggedIn, logout } from '../helpers/helpers_general.js';

const Login = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
